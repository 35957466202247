.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-row,
.user-log-row {
  margin: 3px 0;
}

.product-row:nth-of-type(odd),
.user-log-row:nth-of-type(odd),
.user-list-row:nth-of-type(odd) {
  background-color: lightgrey;
}

.occupation-table {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.occupation-row {
  padding: 2px 0;
  width: 50vw;
}

.page-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 4px;
  width: 22vw;
}

.button-item {
  width: 15vw;
}

.pass-select {
  width: 100%;
}

.checkbox-column {
  width: 10vw;
}

.wide-column {
  width: 35vw;
}

.narrow-column {
  width: 10vw;
}

.narrow-column input {
  width: 4vw;
}

input.red {
  background-color: rgba(255, 0, 64, 0.2);
  border-color: rgba(255, 0, 64, 0.8);
  border-style: solid;
}

input.green {
  background-color: rgba(64, 255, 0, 0.2);
  border-color: rgba(64, 255, 0, 0.8);
  border-style: solid;
}

input.yellow {
  background-color: rgba(255, 192, 0, 0.2);
  border-color: rgba(255, 192, 0, 0.8);
  border-style: solid;
}

input.blue {
  background-color: rgba(0, 191, 255, 0.2);
  border-color: rgba(0, 191, 255, 0.8);
  border-style: solid;
}

.searchbar {
  margin: 0 0 20px 0;
}

.add-new {
  margin: 25px 0 0 0;
}

.pager {
  justify-content: center;
  margin: 25px 0 0 0;
}

.pager-button {
  margin: 0 2px;
}

.pager-current {
  margin: 0 10px;
}
